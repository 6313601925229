import { Chip } from '@mui/material'
import BeachAccessOutlinedIcon from '@mui/icons-material/BeachAccessOutlined'
import terms from 'assets/terms'
import { Absence } from '../agendaTable'
import './style.scss'

interface StatusChipProps {
  status: string;
  width: number;
  absence: Absence;
}

function StatusChip({
  status, width, absence,
}: StatusChipProps) {
  const label = terms.Page.agenda.leavesLabel
  const statusClass = status.toLowerCase()
  const morningClass = (absence.endTimeOfDay === 'MORNING') ? 'morning' : ''
  const afternoonClass = (absence.startTimeOfDay === 'AFTERNOON') ? 'afternoon' : ''
  const afternoonMorningClass = (absence.startTimeOfDay === 'AFTERNOON'
    && absence.endTimeOfDay === 'MORNING') ? 'afternoonMorning' : ''
  return (
    <div>
      {statusClass !== 'denied' && (
        <Chip
          className={`chip ${statusClass} ${morningClass} ${afternoonClass} ${afternoonMorningClass}`}
          sx={{ borderRadius: '10px' }}
          label={width === 32 ? '' : label}
          // eslint-disable-next-line max-len
          icon={status === 'BANK' ? <div /> : <BeachAccessOutlinedIcon className="icon" />}
        />
      )}
    </div>
  )
}

export default StatusChip
